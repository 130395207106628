import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import { Image } from '../../components/Image'
import styled from 'styled-components'

import _, { get, capitalize, kebabCase, omit, isEmpty, last } from 'lodash-es'

import Page from '../../components/page'
import SectionStack from '../../components/section-stack'
import Section from '../../components/section'

import { Grid, Cell } from '../../components/grid'

import { typeHeading50 } from '../../components/typography'
import { Heading2, Subtitle, Body1, Body2, BodySmall, Title, Text } from '../../components/Text'

import { onDesktop, onMobile, isMobile, mobileQuery, desktopQuery } from '@src/utils/responsive'
import { omitProps } from '@src/utils/omit-props'
import { Media } from '../../components/Media'
import { useResponsiveContext } from '../../utils/responsive'
import { animated, Unveil, UnveilGroup } from '../../utils/animated'
import { Fragment } from 'react'

const ProjectHeading = styled(Heading2)`
    max-width: 72%;

    @media ${mobileQuery} {
        margin-bottom: 2rem;
    }

    @media ${desktopQuery} {
        max-width: 52%;
    }
`

const CoverMedia = styled(Media)``

const ProjectInfo = styled(omitProps('overviewCollapsed')(Body1))`
    @media ${mobileQuery} {
        margin-bottom: ${({ overviewCollapsed }) => (overviewCollapsed ? 0.8 : 3.8)}rem;
    }
`

const ProjectInfoTitle = styled(omitProps('overviewCollapsed')(Title))`
    display: none;
    @media ${mobileQuery} {
        margin-bottom: 1.8rem;
    display: block;
    &:after {
      content: '${({ overviewCollapsed }) => (overviewCollapsed ? '+' : '-')}';
      margin-left: 0.5rem;
    }
  }
`

const ProjectOverviewSection = styled.div`
    @media ${mobileQuery} {
        display: flex;
        flex-direction: column-reverse;
    }
`

const ProjectOverviewText = animated(styled.div`
    margin-top: 0rem;
    margin-bottom: 6.7rem;

    ${onDesktop`
    padding: 0 12.2rem 0 0;

    margin-top: 16.1rem;
    margin-bottom: 22.1rem;
  `}

    opacity: 0;
    transform: translateY(5vh);
    transition: opacity 0.8s ease, transform 0.8s ease;

    &.animated--appeared {
        opacity: 1;
        transform: translateY(0px);
    }
`)

const ProjectOverviewLeftColumn = styled(Body1)`
    padding-right: 65%;

    @media ${mobileQuery} {
        display: ${({ overviewCollapsed }) => (overviewCollapsed ? 'none' : 'block')};

        & > :first-child {
            margin-top: 0 !important;
        }
    }
`

const ProjectOverviewInfo = styled(omitProps('mobileVisible')(Body1))`
    ${onMobile`
    display: ${({ mobileVisible }) => (mobileVisible ? 'block' : 'none')};
  `}
`

const ProjectCategories = animated(styled(Subtitle)`
    opacity: 0;
    transform: translateY(5vh);
    transition: opacity 0.8s ease 0.3s, transform 0.8s ease 0.3s;

    &.animated--appeared {
        opacity: 1;
        transform: translateY(0px);
    }
`)

const CoverMediaSection = animated(styled.div`
    overflow: hidden;

    & > div {
        transition: opacity 1.8s ease 0s, transform 1.8s ease 0s;
        transform: translate3d(0, 100%, 1px);
    }

    &.animated--appeared {
        & > div {
            transform: translate3d(0, 0%, 1px);
        }
    }
`)

export const ProjectTemplate = ({
    globals,
    location,
    siteTitle,
    project,
    nextProject,
    ...props
}) => {
    const { isMobile } = useResponsiveContext()

    const categories = project.categories || []
    const sections = project.sections || []

    const [overviewCollapsed, setOverviewCollapsed] = useState(true)

    const coverMedia = get(project, 'coverImage')

    const hasOverviewInfo =
        !isEmpty(project.recognition) ||
        !isEmpty(project.overviewLeftColumnText) ||
        !isEmpty(project.description) ||
        !isEmpty(project.client)

    return (
        <Page
            location={location}
            title={project.title}
            siteTitle={siteTitle}
            navbarRetainer
            nextEntryUrl={!isEmpty(nextProject) && `/projects/${nextProject.handle}`}
        >
            <Section marginTop="medium">
                <ProjectHeading>
                    <UnveilGroup delay={2}>
                        {project.title.split(' ').map((part, index) => (
                            <Fragment key={index}>
                                {index != 0 && ' '}
                                <Unveil key={index}>{part}</Unveil>
                            </Fragment>
                        ))}
                    </UnveilGroup>
                    <ProjectCategories>
                        {categories.map((category, index) => (
                            <span key={`${index}${category.handle}`}>
                                {index != 0 ? ', ' : ''}

                                {category.title}
                            </span>
                        ))}
                    </ProjectCategories>
                </ProjectHeading>
            </Section>

            <ProjectOverviewSection>
                {isMobile ? (
                    <CoverMediaSection>
                        <CoverMedia {...coverMedia} aspectRatio={16 / 9} title={project.title} />
                    </CoverMediaSection>
                ) : (
                    <Section>
                        <CoverMediaSection>
                            <CoverMedia
                                {...coverMedia}
                                aspectRatio={16 / 9}
                                title={project.title}
                            />
                        </CoverMediaSection>
                    </Section>
                )}

                {hasOverviewInfo && (
                    <Section>
                        <ProjectOverviewText>
                            <Grid reverse={'mobile'}>
                                <Cell
                                    xs={12}
                                    sm={5}
                                    style={
                                        isMobile &&
                                        ((!project.recognition &&
                                            !project.overviewLeftColumnText) ||
                                            overviewCollapsed)
                                            ? { display: 'none' }
                                            : {}
                                    }
                                >
                                    <ProjectOverviewLeftColumn
                                        collapseMargin
                                        style={{ marginBottom: 0 }}
                                        overviewCollapsed={overviewCollapsed}
                                    >
                                        {project.client && (
                                            <p>
                                                <Title>Client</Title>
                                                <Subtitle>
                                                    {_.get(project, 'client.name', 'Unknown')}
                                                </Subtitle>
                                            </p>
                                        )}
                                        {project.recognition && (
                                            <p style={{ marginTop: '5rem' }}>
                                                <Title>Recognition</Title>
                                                <Subtitle>{project.recognition}</Subtitle>
                                            </p>
                                        )}

                                        {project.overviewLeftColumnText && (
                                            <Text
                                                collapseMargin
                                                style={{ marginBottom: 0, marginTop: '5rem' }}
                                            >
                                                {project.overviewLeftColumnText}
                                            </Text>
                                        )}
                                    </ProjectOverviewLeftColumn>
                                </Cell>
                                <Cell
                                    xs={12}
                                    sm={7}
                                    style={isMobile && overviewCollapsed ? { marginBottom: 0 } : {}}
                                >
                                    <ProjectInfo
                                        overviewCollapsed={overviewCollapsed}
                                        style={{ marginBottom: 0 }}
                                    >
                                        <ProjectInfoTitle
                                            overviewCollapsed={overviewCollapsed}
                                            onClick={() => setOverviewCollapsed(!overviewCollapsed)}
                                        >
                                            Project Info
                                        </ProjectInfoTitle>

                                        <ProjectOverviewInfo
                                            mobileVisible={!overviewCollapsed}
                                            collapseMargin
                                            style={{ marginBottom: 0 }}
                                        >
                                            {project.description || 'No description'}
                                        </ProjectOverviewInfo>
                                    </ProjectInfo>
                                </Cell>
                            </Grid>
                        </ProjectOverviewText>
                    </Section>
                )}
            </ProjectOverviewSection>

            <SectionStack
                sections={sections}
                globals={globals}
                margin
                marginBottom="medium"
                style={isMobile ? { marginTop: '5rem' } : {}}
            />

            {(project.creditsText1 || project.creditsText2) && (
                <Section marginTop="medium" marginBottom="medium" marginTopMobile="large">
                    <Grid>
                        <Cell md={5}>
                            <BodySmall style={{ marginBottom: 0 }}>Project Credits</BodySmall>
                        </Cell>
                        <Cell md={7}>
                            <div style={{ display: 'flex' }}>
                                <BodySmall style={{ marginRight: '8rem' }} collapseMargin>
                                    {project.creditsText1}
                                </BodySmall>
                                <BodySmall collapseMargin>{project.creditsText2}</BodySmall>
                            </div>
                        </Cell>
                    </Grid>
                </Section>
            )}
        </Page>
    )
}

export const ProjectGatsbyTemplate = ({ pageContext: { globals, handle }, data, ...props }) => {
    const siteTitle = get(data, 'site.siteMetadata.title')
    const project = get(data, 'project', {})

    const nextProject = globals.projects.find((project, index) => {
        if (index == 0) {
            return last(globals.projects).handle == handle
        } else {
            return globals.projects[index - 1].handle == handle
        }
    })
    return <ProjectTemplate {...props} {...{ siteTitle, globals, project, nextProject, globals }} />
}

export default ProjectGatsbyTemplate

export const projectQuery = graphql`
    query ProjectByHandle($handle: String!) {
        site {
            siteMetadata {
                title
            }
        }

        project: contentfulProject(handle: { eq: $handle }) {
            ...projectFragment
        }
    }
`
